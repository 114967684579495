@import '/mixins/fluid-type.scss';

.App {
  font-family: sans-serif;

  text-align: center;
}

h1,
p {
  font-family: 'Titillium Web';

  margin: 0;

  color: #fff;
}

h1 {
  @include fluid-type($screen_min_width, $screen_max_width, 16px, 120px);
}

p {
  margin-top: 0.1em;

  @include fluid-type($screen_min_width, $screen_max_width, 14px, 30px);
}

body {
  -webkit-animation: BG 58s ease infinite;
  -moz-animation: BG 58s ease infinite;
  animation: BG 58s ease infinite;

  background: linear-gradient(312deg, #394896, #537b40, #6b4c7d);
  background-size: 600% 600%;
}

.banner {
  display: flex;
  flex-direction: column;

  width: 100vw;
  height: 100vh;

  justify-content: center;
}

.text-container {
  box-sizing: border-box;
  width: 70%;
  max-width: 1200px;
  margin: auto;
  padding: 1.2em;

  border-radius: 5px;
  background: #444;
  // background: #561187;
}

.text-container-inner {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 1em;

  border: 1px solid #e9fff7;
  border-radius: 5px;
}

.icon-container {
  display: flex;

  margin-top: 1.5em;

  flex-wrap: wrap;
  justify-content: center;
}

.icon {
  width: 55px;
  max-width: 100%;
  max-height: 55px;
}

.icon-group {
  position: relative;

  max-width: 50%;
  margin: 0 10px;

  a {
    &:hover,
    &:focus {
      .icon {
        path {
          fill: #98a795;
        }
      }

      .opens-new {
        fill: #98a795;
      }
    }
  }
}

.icon {
  path {
    transition: fill 0.3s;

    fill: #e9fff7;
  }
}

.opens-new {
  position: absolute;
  top: -10px;
  right: -10px;

  width: 14px;

  transition: fill 0.3s;

  fill: #fff;
}

@media (max-width: 480px) {
  h1 {
    word-break: word-break;
  }

  .text-container {
    width: 80%;
  }
}

@-webkit-keyframes BG {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

@-moz-keyframes BG {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

@keyframes BG {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}
